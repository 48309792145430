.frontpage-heading{
    font-size: 32px;
    font-weight: 700;
    font-family: "Aventa", sans-serif;
    color: #DCF6FC;
    
}

.frontpage-heading .highlighted{
    color: #70CEFA;
}

.spartan-link{
    text-decoration: none;
    color: #66CAF9; 
    font-family: '"Aventa", sans-serif';
}

.spartan-heading {
    font-size: 32px;
    color: #DCF6FC;
    font-family: "Aventa", sans-serif;
}

.spartan-heading-bold {
    font-size: 32px;
    color: #DCF6FC;
    font-weight: 900;
    font-family: "Aventa", sans-serif;
}

.empty-vehicle-container {
    max-width: 500px;
    margin: 0px auto;
}

.spartan-primary-text {
    color: #DCF6FC;
}

.spartan-secondary-text {
    color: #72ACBB;
}

.spartan-panel {
    background-color: #083039;
    color: #fff;
}