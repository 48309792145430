.spartan-navbar {
    width: 100vw;
    height: 75px;
    background-color: rgba(0,0,0,0);
    position: absolute;

    .logo-container{
        width: 200px;
        margin: 0 auto;
        height: 75px;
        padding-top: 20px;
    }

    .organization-container{
        width: 200px;
        position: absolute;
        top:25px;
        right:0px;

        select {
            background-color: rgba(0,0,0,0);
            color: #fff;
            border: none;

            option {
                background-color: #000;
            }
        }
    }
}

